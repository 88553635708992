import React from 'react'



const Add:React.FC= () => {
  return (
    <>
        <div className="p-4  flex flex-row items-center justify-center space-y-3 bg-primary">
          <h2 className="text-lg text-center xl:text-5xl font-poppins text-white font-bold">
         100+
          </h2>

          <p className=" ml-4 text-center text-white text-base md:text-lg lg:text-2xl ">
           Hackers
          </p>
        </div>
    </>
  )
}

export default Add