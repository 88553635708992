import React , { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../../images/hacks/header-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Web Developer", "Web Designer", "UI/UX Designer" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
      <div className="w-4/5  mx-auto flex flex-col-reverse xl:flex-row items-center space-y-6 xl:space-y-0 xl:space-x-10">
            <div className="w-full xl:w-3/4 mt-12 mx-auto flex xl:items-center xl:justify-center">
              <div className="flex  flex-col items-start space-y-6">
                <h2 className="text-2xl md:text-3xl xl:text-5xl font-Poppins font-bold tracking-normal text-white   xl:leading-snug">
                  🚀 Join the DevMeet Hackathon
                  <br />
                  <span className="text-transparent  bg-clip-text bg-gradient-to-r from-yellow-400 to-purple-600">
                    Build, Collaborate, Innovate!
                  </span>{" "}
                  🌟
                </h2>

                <p className="text-sm md:text-lg font-medium font-Poppins tracking-normal text-gray-100 xl:leading-snug">
                  we welcome anyone with an interest in learning and innovation.
                  Join us for regular meetups, events, and online forums to stay
                  connected and engaged. Let's build the future of technology
                  together! 🚀
                </p>

                <div className="text-3xl sm:w-full lg:w-1/2 ">
                  {/* <Button
                    text="Join the Team"
                    width="full"
                    classNames="py-5"
                    to="https://rb.gy/ndfjoy"
                  /> */}
                  <div className=" flex flex-row items-center justify-center text-center text-white font-Poppins text-2xl rounded-xl shadow-lg shadow-primary bg-theme_two border px-3 py-4">
                    <span className="font-bold text-yellow-500">Date</span>: 28
                    APRIL 2023
                  </div>
                </div>
                <p className="text-white    text-sm">
                  Come together to reach the peak.{" "}
                  {/* <span className="font-medium"> 200+ members</span>. */}
                </p>
              </div>
            </div>
            <div className="w-full xl:w-2/5 flex flex-col items-start space-y-8">
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__zoomIn" : ""
                    }
                  >
                    <img src={headerImg} alt="" />
                  </div>
                )}
              </TrackVisibility>
            </div>
          </div>
      </Container>
    </section>
  )
}
