export const data = [
  {
    id: 1,
    title: "Who can participate in Devmeet Student Hackathon?",
    desc:
      "Our hackathon is open to all current students, regardless of their academic background or experience level. Whether you're studying computer science, engineering, or any other field, we welcome you to participate and challenge yourself.",
  },
  {
    id: 2,
    title: "Is there any registration fee for participating in the hackathon?",
    desc:
      "No, there is no registration fee for participating in the Devmeet Student Hackathon. It's completely free!",
  },
  {
    id: 3,
    title: "What if I don't have any coding experience?",
    desc:
      "Our hackathon is open to students of all experience levels, including those who are just starting out. We encourage beginners to participate and learn new skills during the hackathon.",
  },
  {
    id: 4,
    title: "What kind of projects are eligible for the hackathon?",
    desc:
      "We welcome all kinds of innovative and creative projects, as long as they align with the hackathon theme and comply with our rules and regulations.",
  },
  {
    id: 5,
    title: "What kind of prizes can I win?",
    desc:
      "We offer a variety of prizes for the winners, including cash prizes, gadgets, and more. The exact prize details will be announced on our website and social media channels.",
  },
]
