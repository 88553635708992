import React from "react"
import Button from "../components/common/Button"

const Venue: React.FC = () => {
  return (
    <>
      <section
      id="venue"
      className="p-16 text-center mx-auto space-y-6 bg-theme_white ">
        <h1 className="pt-4 w-4/5 mx-auto text-4xl  font-poppins text-white font-bold">
        Venue
        </h1>
        <div className="text-left items-center justify-center">
          <h2 className="pt-5 w-4/5 mx-auto text-lg xl:text-2xl font-poppins text-white xl:leading-24">
            {"📍"} St. Xavier's Catholic College of Engineering,
          </h2>

          <h2 className="pt-4 w-4/5 mx-auto text-lg xl:text-2xl font-poppins text-white  xl:leading-24">
            {" 👉"} Chunkankadai, Nagercoil, Kanyakumari District, Tamil Nadu, India

          </h2>
        </div>
        <div className="space-y-5">

          {/* <h2 className="pt- w-4/5 mx-auto text-lg xl:text-4xl font-poppins text-white font-bold xl:leading-24">
            
          </h2> */}
        </div>
        {/* <div className="relative px-7 py-8 w-full sm:w-1/4 mx-auto font-semibold">
        <Button text="Watch how it works" to="" />

        </div> */}
      </section>
    </>
  )
}

export default Venue
