import React from "react"
import { graphql, useStaticQuery, withPrefix } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Features from "../Hackathon/Features"
import Footer from "../components/common/Footer"
import Button from "../components/common/Button"
import AboutUs from "../Hackathon/AboutUs"
import Team from "../Homepages/team"
import Sponsers from "../Hackathon/Sponsors"
import FAQS from "../Hackathon/faq/faqs"
import Welcome from "../images/welcome.mp4"
import Badge from "../Homepages/badge"
import HackLayout from "../components/hacksLayout"
import StarfieldAnimation from "react-starfield-animation"
import Helmet from "react-helmet"
import Add from "../Hackathon/Add"
import Schedule from "../Hackathon/schedule/Schedule"
import Contact from "../Hackathon/contact"
import Venue from "../Hackathon/Venue"
// import Corporate from "../Hackathon/Corporate"
import TrackVisibility from "react-on-screen"
import "animate.css"
import { Banner } from "../components/common/Banner"

const Hackathons = () => {
  const HackathonsQueryData = useStaticQuery(graphql`
    query HackathonsQuery {
      Head: file(
        name: { eq: "hackbanner" }
        absolutePath: { regex: "/images/hackathons/" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }
    }
  `)

  const isBrowser = typeof window !== "undefined"

  const HeadImg = getImage(HackathonsQueryData.Head)

  return (
    <>
      {/* <Cursor /> */}
      <HackLayout>
        <SEO title="DevMeet Community Hackathon " />
        <Banner />
        {/* About Us Section */}
        <AboutUs />
        <Add />

        <Schedule />
        {/* Features Section */}
        {/* <Features /> */}
        {/* Mentors */}
        {/* Grow Section */}
        {/* <Corporate /> */}

        <Sponsers />
        {/* How it Works */}
        {/* <Team /> */}

        <Badge />
        {/* <HowitWorks /> */}

        <FAQS />
        <Venue />
        <Contact />
        {/* Footer Section */}
        <Footer />
      </HackLayout>
    </>
  )
}

export default Hackathons
